export default {
  /* 文案说明 */
  create_clue_reminder_btn: "新建提醒",

  /* 表格字段 */
  clue_t_index: "序号",
  clue_t_mask_mobile: "手机号",
  clue_t_remark: "备注信息",
  clue_t_created_time: "提醒时间",

  /* 新增提醒modal */
  alterPassTitle: "新增提醒",
  mobileLabel: "手机号",
  monilePlaceholder: "请输入手机号",
  remarkLabel: "备注信息",
  remarkPlaceholder: "请输入备注信息",
  cancelText: "取消",
  comfirmText: '确认'
};
