import "./App.scss";
import React, { Suspense } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { routers } from "routers/index";
import "./i18n/i18n";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";

export default function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Routes location={""}>
          {routers.map((item, i) => {
            if (Array.isArray(item.children)) {
              return (
                <Route
                  key={i}
                  path={item.path}
                  element={
                    <Suspense>
                      <item.component />
                    </Suspense>
                  }
                >
                  {item.children.map((child, k) => (
                    <Route
                      key={i}
                      path={item.path + child.path}
                      element={
                        <Suspense>
                          <child.component />
                        </Suspense>
                      }
                    />
                  ))}
                </Route>
              );
            }
            return (
              <Route
                key={i}
                path={item?.path}
                element={
                  <Suspense>
                    <item.component />
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}
