/* 全局公用翻译 */
export default {
  businessCooperation: "商务合作",
  searchEmpty: "无搜索结果",
  search: "搜索",
  reset: "重置",
  partner: "商务合作伙伴工作台",
  personal_center: "个人中心",
  logout: "退出",
  hint: "温馨提示",
  hint_txt: "确定要退出登录吗?",
  logout_ok: "退出登录成功",
  cancel: "取消",
  ok: "确定",
};
