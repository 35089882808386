import React from "react";
import { Layout, Menu, MenuProps, Dropdown, Modal, message } from "antd";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "hooks/useTranslate";
import classNames from "classnames";
import { getLogout } from "api/user";
import useUserInfo from "@/hooks/userInfo";
import { Login, personal_center } from "@/routers/routeName";

const { Header } = Layout;
interface IProps {
  collapsed: any;
}
/* 头部导航栏 */
export default function OtherPageAudit(props: IProps) {
  const { tr } = useTranslate("common");
  const navigate = useNavigate();

  /** 用户信息 */
  const { userInfo } = useUserInfo();

  /* 退出登录 */
  const onLogout = async () => {
    const [suc] = await getLogout();
    if (suc) {
      message.success({ content: tr("logout_ok") });
      navigate(Login);
    }
  };
  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "center":
        navigate(personal_center);
        break;
      case "logout":
        Modal.confirm({
          title: tr("hint"),
          icon: <ExclamationCircleOutlined />,
          content: tr("hint_txt"),
          okText: tr("ok"),
          cancelText: tr("cancel"),
          onOk: () => onLogout(),
        });
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: tr("personal_center"),
          key: "center",
        },
        {
          label: tr("logout"),
          key: "logout",
        },
      ]}
    />
  );

  return (
    <Header className={classNames(styles.head_wrap, "flex-between")}>
      {props.collapsed}
      <Dropdown overlay={menu}>
        <p className={classNames(styles.name, "flex-center")}>
          <i className={classNames(styles.h_icon, "g-bg-img")}></i>
          <span className={classNames("g-ellipsis", styles.h_title)}>
            {userInfo?.companyName}
          </span>
          <DownOutlined />
        </p>
      </Dropdown>
    </Header>
  );
}
