import Axios from "axios";
import { getToken } from "utils/localStorage";
import { message } from "antd";
import type { UnexpectedError } from "./types";
import { Login } from "@/routers/routeName";
import { history } from "hooks/history";

const axios = Axios.create();

const { REACT_APP_BASE_URL } = process.env;

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";
axios.defaults.timeout = 10000; // 请求的超时时间
axios.defaults.baseURL = REACT_APP_BASE_URL; // 设置地址

// 请求拦截器
axios.interceptors.request.use((config: any) => {
  const token = getToken();
  if (token?.accessToken && config.headers) {
    config.headers.accessToken = token?.accessToken;
  }
  return config;
});

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    const status: number = res.status;

    /* 服务出错 */
    if (status !== 200) {
      const errorMsg = res.data?.message || "Serve error";
      message.error(errorMsg);
      return Promise.reject();
    } else if (status == 200) {
      // 排出返回blob流格式
      if (
        res.headers["content-type"] ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
      ) {
        return Promise.resolve(res as any);
      }
      return Promise.resolve(res.data);
    }

    /* 未登录 */
    if (res.data.code === "11") {
      message.error(res.data?.message || "Serve error");
      localStorage.clear();
      sessionStorage.clear();
      history.replace(Login);
      return Promise.reject();
    }

    /* 请求报错 */
    if (res.data.code != "200") {
      return Promise.reject(res.data);
    }

    return Promise.resolve(res.data);
  },
  (err) => {
    message.error(err.message || "Serve error");
    return Promise.reject(err);
  }
);

/**
 * await 包装函数
 * @param promise
 * @param defaultShowToast 展示请求错误 toast 提示
 * @returns promise
 * @description
 */

export const awaitWrapper = async <T, K = UnexpectedError>(
  promise: Promise<T>,
  defaultShowToast?: boolean
): Promise<[undefined, K] | [T, undefined]> => {
  try {
    const response = await promise;
    return [response, undefined];
  } catch (error: any) {
    if (defaultShowToast && error) {
      message.error(error?.message || "接口报错,请稍后重试");
      return [undefined, error];
    }
    return [undefined, error];
  }
};

export default axios;
