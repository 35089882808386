import i18n from "i18next";
import zh_CN from "./locales/zh_CN/index";
import en_US from "./locales/en_US/home";
import { initReactI18next } from "react-i18next";

const lng = "zh";
export const zh = "zh";
export const en = "en";

i18n.use(initReactI18next).init({
  resources: {
    [zh]: {
      translation: zh_CN,
    },
    [en]: {
      translation: en_US,
    },
  },
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
