import React from "react";
import styles from "./index.module.scss";
import { useTranslate } from "hooks/useTranslate";
import classNames from "classnames";

/* 审核中页面 */
export default function OtherPageAudit() {
  const { tr } = useTranslate("otherPage");
  return (
    <div className={classNames(styles.wrap, "flex-center")}>
      <div className={styles.content}>
        <img
          src="https://web-data.zmlearn.com/image/p3gtnSRT1daYt48aWkb6sr/audit.png"
          alt=""
        />
        <p className={styles.title}>{tr("audit_ing")}</p>
        <p className={styles.des}>{tr("audit_contact")}</p>
      </div>
    </div>
  );
}
