import common from "./common";
import home from "./home";
import login from "./login";
import listManagement from "./listManagement";
import otherPage from "./otherPage";
import personalCenter from "./personalCenter";
import clueReminder from "./clueReminder";

export default {
  common,
  home,
  login,
  listManagement,
  otherPage,
  personalCenter,
  clueReminder,
};
