interface IUserInfo {
  /* token */
  accessToken?: string;

  /* 公司名 */
  companyName: string;

  /* id */
  id?: number | string;

  refreshToken?: null;

  /* 合作商状态 0:正常,1:审核中,2:已停用 */
  status?: number | string;

  /* 状态文案描述 */
  statusName: string;
}
export const setLocal = (key: string, val: any) => {
  localStorage.setItem(key, val);
};

export const getToken = () => {
  const token = localStorage.getItem("userInfo");
  return token ? JSON.parse(token) : {};
};

export const getUserInfo = () => {
  const userInfo = localStorage.getItem("userInfo");
  return userInfo ? JSON.parse(userInfo) : {};
};

export const setUserInfo = (data: IUserInfo) => {
  localStorage.setItem("userInfo", JSON.stringify(data));
};
