import { updateUserInfo } from "@/store/userSlice";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./useStore";

/**
 * 获取用户信息
 * @description 登录用户信息持久化 页面刷新数据保存
 */
const useUserInfo = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.user.userInfo);

  useEffect(() => {
    const localUserInfo = localStorage.getItem("userInfo");
    if (localUserInfo) {
      dispatch(updateUserInfo(JSON.parse(localUserInfo)));
    }
  }, []);

  return {
    userInfo,
  };
};

export default useUserInfo;
