import axios, { awaitWrapper } from "request/axios";

/* 个人中心 */
export const getCompany = () => {
  return awaitWrapper(axios.get("/v1/partner/operation/company"), true);
};

/* 退出 */
export const getLogout = () => {
  return awaitWrapper(axios.get("/v1/partner/operation/user/logout"), true);
};

/* 获取当前合作商的状态 */
export const getStatus = () => {
  return awaitWrapper(axios.get("/v1/partner/operation/user/status"), true);
};

/* 菜单权限 */
export const getMenuPermission = () => {
  return awaitWrapper(
    axios.get(
      `/v1/partner/operation/listableManage/dynamicField?permissionCodes=menu_permission_code`
    ),
    true
  );
};
