export default {
  /* 过滤表单 */
  filter_name: "学生姓名",
  filter_phone: "手机号",
  filter_phone_p: "请输入手机号",
  filter_email: "邮箱",
  filter_email_p: "请输入邮箱",
  
  filter_name_p: "请输入姓名",
  filter_code: "渠道代码",
  filter_code_p: "请选择",
  filter_register: "注册",
  filter_register_s: "开始时间",
  filter_register_e: "结束时间",
  filter_first: "首次接通",
  filter_course: "试听课首次排课",
  filter_attend: "试听课首次出席",
  filter_sign: "首次签单",

  /* 文案说明 */
  l_bulk_import: "批量导入名单",
  l_bulk_import_t: "* 以下涉及时间字段均以北京时间为准",

  /* 表格字段 */
  t_index: "序号",
  t_name: "姓名",
  t_use_num: "用户号",
  t_code: "渠道代码",
  t_channelKey: "key",
  t_register: "注册时间",
  t_connect: "首次接通时间",
  t_course: "试听课首次排课时间",
  t_sign: "首次签单时间",
  t_money: "首次签单金额",
};
