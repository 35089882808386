export default {
    login: '登录',
    registry: '注册',
    title: '合作商登录',
    telLogin: '手机登录',
    emailLogin: '邮箱登录',
    telPlaceholder: '请输入手机号*',
    passPlaceholder: '密码*',
    emailPlaceholder: '请输入邮箱*',
    loginBtn: '登录',
    noAccount: '没有账号？',
    goRegistry: '点击前往注册',
    companyInfo: '公司信息',
    connectInfo: '联系信息',
    otherInfo: '其他联系方式',
    passPlaceholderRe: '请输入8~16位字符作为密码*',
    passPlaceholderReA: '请再次输入密码*',
    agreeRe: '注册即代表您同意',
    reAgreement: '《用户协议》',
    yinSiAgreement: '《隐私协议》',
    networkAgreement: '《互联网信息服务协议》',
    registryed: '已经注册？',
    goLogin: '点击前往登录',
    companyNamePlaceholder: '公司名称*',
    countryPlaceholder: '国家/地区*',
    addressPlaceholder: '地址*',
    contactsPlaceholder: '联系人称呼*',
    knowFormWherePlaceholder: '您从哪里得知本合作伙伴计划？',
    next: '下一步',
    errEmail: '邮箱格式不正确',
    errTel: '请输入正确的手机号',
    errPass: '密码不正确',
}