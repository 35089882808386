import React from "react";
import styles from "./index.module.scss";
import { useTranslate } from "hooks/useTranslate";
import classNames from "classnames";

/* 合作结束页面 */
export default function OtherPageEnd() {
  const { tr } = useTranslate("otherPage");
  return (
    <div className={classNames(styles.wrap, "flex-center")}>
      <div className={styles.content}>
        <img
          src="https://web-data.zmlearn.com/image/eDAGbLLZbW6MsrbSHn7LRH/cooperation-ends.png"
          alt=""
        />
        <p className={styles.title}>{tr("end_cooperation")}</p>
        <p className={styles.des}>{tr("end_next")}</p>
      </div>
    </div>
  );
}
