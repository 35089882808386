/* 个人中心 */
export default {
  title: "个人信息",
  id: "供应商ID",
  name: "公司名称",
  city: "国家/地区",
  linkman: "联系人姓名",
  phone: "联系人电话",
  email: "联系人邮箱",
  address: "地址",
  other: "其他联系方式",
  alterPass: "修改密码",
  alterPassTitle: "修改密码",
  curentPassLabel: "当前密码",
  curentPassPlaceholder: "请输入",
  newPassLabel: "新密码",
  newPassPlaceholder: "请输入字母、数字组合，不少于8位",
  confirmNewPassLabel: "确认新密码",
  confirmNewPassPlaceholder: "请再次输入密码",
  cancelText: "取消",
  comfirmText: '确认提交'
};
