import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserInfo } from "./types";
interface UserState {
  userInfo?: IUserInfo;
}

const initialState: UserState = {
  userInfo: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, { payload }: PayloadAction<IUserInfo>) => {
      localStorage?.setItem("userInfo", JSON.stringify(payload));
      state.userInfo = payload;
    },
  },
});

export const { updateUserInfo } = userSlice.actions;

export default userSlice.reducer;
