import React, { useState, useEffect, lazy } from "react";
import { Layout, Menu, MenuProps } from "antd";
import { menuList } from "routers/index";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { needLoginRouters } from "routers/index";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Head from "./header";
import End from "pages/otherPage/end";
import Audit from "pages/otherPage/audit";
import { useTranslate } from "hooks/useTranslate";
import { useLocation } from "react-router-dom";
import { getUserInfo, setUserInfo } from "utils/localStorage";
import { Login } from "routers/routeName";
import { getStatus, getMenuPermission } from "@/api/user";
import { IDynamicField } from "@/utils/types/clueReminder";

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export default function LayoutComponents() {
  const { tr } = useTranslate("common");
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo: any = getUserInfo();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<any>();
  const [status, setStatus] = useState(userInfo.status); // 合作商状态,0:待关联 ,1:已关联 ,2: 停用
  const [menuAuthList, setMenuAuthList] = useState([...menuList]);
  const [userPermissions, setUserPermissions] = useState<any>([]);

  const items: MenuItem[] = menuAuthList.map((item) => 
    getItem(
      <Link to={item.path}>{item.name}</Link>,
      item.path,
      item.icon && <img src={item.icon} className={styles.icon_img} />
    )
  );

  menuAuthList.map(item => {
    userPermissions.push(item.name)
  })
  /* 获取菜单权限 */
  const queryMenuPermissionList = async () => {
    const [suc, err] = await getMenuPermission();
    if (suc && suc?.data?.menuFields.length > 0) {
      let _list: IDynamicField[] = suc.data.menuFields;
      let _menuAuthList = menuAuthList;
      _list.map(item => {
        if(item.permissionExt === 'clueReminder') {
          _menuAuthList.map(k => {
            if(k.name === '新建提醒') {
              k.ifHasAuth = true;
              return k;
            } else {
              return k;
            }
          })
        }
      })
      setMenuAuthList(menuAuthList);
    }
  };

  /* 获取合作商状态 */
  const getStatusReq = async () => {
    const [suc] = await getStatus();
    if (suc) {
      setStatus(suc.data);
      setUserInfo({ ...userInfo, status: suc.data });
    }
  };

  /* 选中菜单 */
  const onChangeMenu = (data: any) => {
    setSelectedKeys(data.key);
  };

  /* 路由改变 -- 设置选中状态 */
  useEffect(() => {
    setSelectedKeys(location.pathname);
  }, [location.pathname]);

  /* 未登录则去登录页面 */
  useEffect(() => {
    if (!userInfo?.accessToken) {
      navigate(Login);
    }
  }, []);

  /* 合作商状态 */
  useEffect(() => {
    if (userInfo?.accessToken) {
      queryMenuPermissionList();
      getStatusReq();
    }
  }, []);

  useEffect(() => {
    // 根据权限过滤菜单数据  
    const filteredMenuData = menuAuthList.filter(item => item.ifHasAuth);
    setMenuAuthList(filteredMenuData);
  }, []);

  return (
    <>
      {userInfo?.accessToken && (
        <div className={styles.layout_wrap}>
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              collapsedWidth={64}
            >
              <div className={styles.logo}>
                {collapsed && (
                  <img
                    className={styles.logo_img_s}
                    src="https://web-data.zmlearn.com/image/o2CiXpcb7mzZZ5eSTs51Yd/logo_f_s.png"
                  />
                )}
                {!collapsed && (
                  <div>
                    <img
                      className={styles.logo_img}
                      src="https://web-data.zmlearn.com/image/nmSVFYJcF2cZeRfVuRxhwr/logo_f.png"
                    />
                    <h3 className={styles.title}>{tr("partner")}</h3>
                  </div>
                )}
              </div>

              {status === 1 && (
                <Menu
                  defaultSelectedKeys={[needLoginRouters[0].path]}
                  selectedKeys={[selectedKeys]}
                  mode="inline"
                  items={items}
                  onClick={onChangeMenu}
                />
              )}
            </Sider>
            <Layout>
              <Head
                collapsed={React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              />
              <Content className={styles.content}>
                <div>
                  {status === 0 && <Audit />}
                  {status === 1 && <Outlet />}
                  {status === 2 && <End />}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
}
