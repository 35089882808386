/* 其他页面 */
export default {
  /* 合作结束 */
  end_cooperation: "您好！当前合作已结束",
  end_next: "期待与您下次继续合作～",

  /* 审核页面 */
  audit_ing: "您好，您的申请正在审核中...",
  audit_contact:
    "请等待AirClass工作人员与您取得联系，或发送邮件至Business@air-class.com",
};
