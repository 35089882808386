export default {
  login: "登录",
  slogan: "成为AirClass的合作伙伴",
  apply: "申请合作商",
  we: "我们，",
  logon: "AirClass",
  introduce:
    "AirClass成立于2014年，是全球专业的教育品牌，由多位上市科技教育公司的高管联合创立。AirClass专注于为3-18岁的来自世界各地的学生提供定制辅导，涵盖所有的学术科目和兴趣课程，旨在成为全球最大的在线1对1教育服务公司。",
  advantage: "强大的师资力量和服务团队",
  facultyTil: "教研和老师，品牌的第一代言人",
  faculty1: "AirClass拥有国际化资深教研团队和教师力量；",
  faculty2:
    "教研团队聚集了海内外的教育学、儿童心理学教授和拥有多位一线教学经验超过10年以上的老师，共同为孩子研究科学、丰富的课程内容；",
  faculty3:
    "入驻AirClass的老师不仅毕业于知名院校、拥有对应学科的资格证书，而且需要经过5轮筛选、2次书面考核、5次磨课考核、8次作业评分等综合评比。",
  teamTil: "贴心的服务团队，保障孩子学习体验",
  team1:
    "AirClass的每一位课程顾问都坚守着从学生出发的职业理念，了解学生需求，提供最合适的学习方案；",
  team2:
    "AirClass始终坚持团队的定期培训，每一位员工都在为AirClass的使命和愿景而努力；",
  team3:
    "完善的全球协同机制，确保学生在任何时间、任何地点都能够获得及时和有效的服务。",

  /* 合作商申请弹窗 */
  partnerTil: "合作商申请",
  partnerName: "合作商名称*",
  partnerNameErr: "请输入合作商名称",
  area: "国家/地区*",
  areaErr: "请选择国家地区",
  address: "地址*",
  addressErr: "请输入地址",
  contacts: "联系人称呼*",
  contactsErr: "请输入联系人",
  phone: "请输入手机号*",
  phoneErr: "请输入手机号",
  email: "邮箱*",
  emailErr: "请输入邮箱",
  agency: "过往代理品牌",
  submit: "提交",
  successTil: "您已成功申请合作商，请等待我们的工作人员",
  successBtn: "我知道了",
  successInfo: "提交成功",

  /* 卡片 */
  cardText1: "新学员来自老学员推荐",
  cardText2: "优秀双语教师",
  cardText3: "学员的不二选择",
  cardText4: "老师平均教学年龄",
  cardText5: "范围覆盖的国家",
};
