import { lazy } from "react";
import Layout from "components/layout/index";
import {
  Login,
  list_management,
  personal_center,
  list_management_upload,
  clue_reminder,
  Home,
} from "./routeName";

interface IRoute {
  /* 路径 */
  path: string;
  /* 标题 */
  name?: string;
  /* 图标 */
  icon?: string;
  /* 组件 */
  component: any;
  /* 是否需要登录 */
  isLogin?: boolean;
  /* 是否展示在侧边栏 */
  isHide?: boolean;
  /* 子页面 */
  children?: IRoute[];
  /* 是否有权限 */
  ifHasAuth?: boolean;
}

/* 需要登录的路由 */
export const needLoginRouters: IRoute[] = [
  {
    path: list_management,
    name: "名单管理",
    ifHasAuth: true,
    icon: "https://web-data.zmlearn.com/image/4HShatKrVQye1XsU7Zdwne/icon_management.svg",
    component: lazy(() => import("pages/listManagement/index")),
  },
  {
    path: personal_center,
    name: "个人中心",
    ifHasAuth: true,
    icon: "https://web-data.zmlearn.com/image/qMQWRHGcN42UHUMQcY6YaA/icon_my.svg",
    component: lazy(() => import("pages/personalCenter/index")),
  },
  {
    path: list_management_upload,
    name: "批量导入",
    icon: "icon_ourtutors",
    isHide: true,
    ifHasAuth: true,
    component: lazy(() => import("pages/uploadList/index")),
  },
  {
    path: clue_reminder,
    name: "线索提醒",
    icon: "https://web-data.zmlearn.com/image/4HShatKrVQye1XsU7Zdwne/icon_management.svg",
    ifHasAuth: false,
    component: lazy(() => import("pages/clueReminder/index")),
  },
];

export const routers: IRoute[] = [
  {
    path: Home,
    component: lazy(() => import("pages/home/index")),
    isLogin: false,
  },
  {
    path: Login,
    component: lazy(() => import("pages/login/index")),
    isLogin: false,
  },
  {
    path: '/userAgreement',
    component: lazy(() => import("@/pages/agreement/user")),
    isLogin: false,
  },
  {
    path: '/netAgreement',
    component: lazy(() => import("@/pages/agreement/user")),
    isLogin: false,
  },
  {
    path: '/policyAgreement',
    component: lazy(() => import("@/pages/agreement/user")),
    isLogin: false,
  },
  /* 需要登录 */
  {
    path: "/",
    component: Layout,
    children: needLoginRouters,
    isLogin: true,
  },
  {
    path: "*",
    component: lazy(() => import("pages/home/index")),
  },
  {
    path: '/cooperationNetPolicy',
    component: lazy(() => import("@/pages/agreement/user")),
    isLogin: false,
  },
];

/* 不需要登录路由 */
export const needNotLoginRouters = ["/home", "/login"];

const checkAuth = (routers: any, path: string) => {
  for (const data of routers) {
    if (data.path === path) return data;
    if (data.children) {
      const res: any = checkAuth(data.children, path);
      if (res) return res;
    }
  }
  return null;
};

const routerList = () => {
  routers.map((item, i) => {
    // 需要登录 并且已经是登录态
    if (item.isLogin) {
      return;
    }
  });
};

/* 展示侧边菜单 */
export const menuList = needLoginRouters.filter((item) => !item.isHide);
