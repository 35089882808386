import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useTranslate(fileName?: string) {
  const { t } = useTranslation();

  const tr = useCallback(
    (namespace: string) => {
      const str = t(`${fileName}.${namespace}`);
      return str ? str : `${fileName}.${namespace}`;
    },
    [fileName]
  );

  return {
    tr,
  };
}
